body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'poppins-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
@font-face {
  font-family: 'poppins-regular';
  src: local(poppins-regular),
    url(./assets/fonts/poppins-regular.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.minHeight500 {
  min-height: 500px !important;
}

.minHeightCard {
  min-height: 491px !important;
  /* overflow-y: auto; */
}

.eGEGHg {
  overflow-y: auto !important;
}

.breadcrumb li:not(.active) {
  cursor: pointer;
}

.breadcrumb li {
  text-transform: capitalize;
}

.far {
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

.panel-accordion {
  background-color: #f4f5fb;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}

.form-legend {
  margin: 20px;
  width: 155px;
}
.form-fieldset {
  border: 1px #c5c5c5 solid;
}

.rdtCounters > div {
  float: left;
  width: 43px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%;
}

/* PWA css */
:root {
  --bg-white: white !important;
  --bg-1: #eff9f5 !important;
  --bg-2: #3ab586 !important;
  --bg-3: #0b7e52 !important;
  --bg-4: #ffbf59 !important;
  --bg-5: #1d9fc9 !important;
  --bg-6: #654ab4 !important;
  --bg-7: #f08a5d !important;
  --bg-8: #f64a48 !important;
  --bg-9: #c4c4c4 !important;
  --bg-10: #717171 !important;
  --bg-11: #414141 !important;
  --bg-12: #282828 !important;
}
.pwa-color-white {
  color: var(--bg-white) !important;
}
.pwa-color-white-bg {
  background-color: var(--bg-white) !important;
}
.pwa-color-1 {
  color: var(--bg-1) !important;
}
.pwa-color-1-bg {
  background-color: var(--bg-1) !important;
}
.pwa-color-2 {
  color: var(--bg-2) !important;
}
.pwa-color-2-bg {
  background-color: var(--bg-2) !important;
}
.pwa-color-3 {
  color: var(--bg-3) !important;
}
.pwa-color-3-bg {
  background-color: var(--bg-3) !important;
}
.pwa-color-4 {
  color: var(--bg-4) !important;
}
.pwa-color-4-bg {
  background-color: var(--bg-4) !important;
}
.pwa-color-5 {
  color: var(--bg-5) !important;
}
.pwa-color-5-bg {
  background-color: var(--bg-5) !important;
}
.pwa-color-6 {
  color: var(--bg-6) !important;
}
.pwa-color-6-bg {
  background-color: var(--bg-6) !important;
}
.pwa-color-7 {
  color: var(--bg-7) !important;
}
.pwa-color-7-bg {
  background-color: var(--bg-7) !important;
}
.pwa-color-8 {
  color: var(--bg-8) !important;
}
.pwa-color-8-bg {
  background-color: var(--bg-8) !important;
}
.pwa-color-9 {
  color: var(--bg-9) !important;
}
.pwa-color-9-bg {
  background-color: var(--bg-9) !important;
}
.pwa-color-10 {
  color: var(--bg-10) !important;
}
.pwa-color-10-bg {
  background-color: var(--bg-10) !important;
}
.pwa-color-11 {
  color: var(--bg-11) !important;
}
.pwa-color-11-bg {
  background-color: var(--bg-11) !important;
}
.pwa-color-12 {
  color: var(--bg-12) !important;
}
.pwa-color-12-bg {
  background-color: var(--bg-12) !important;
}

.pwa-card {
  border-radius: 16px;
  box-shadow: 0 10px 30px 0 rgba(112, 136, 210, 0.1) !important;
}
.pwa-card-1 {
  border-radius: 16px;
  box-shadow: 0px 7px 8px 0px rgb(16 16 16 / 13%) !important;
}
.pwa-card-noshadow {
  border-radius: 16px;
}
.pwa-card-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--bg-12);
}
.pwa-card-disabled {
  border-radius: 16px;
  background-color: #f0f0f0b0 !important;
}

.light-shadow {
  box-shadow: 0 2px 2px 2px rgb(17 101 47 / 10%) !important;
}

.c-pointer {
  cursor: pointer !important;
}
.c-default {
  cursor: default !important;
}
.none {
  display: none !important;
}

.rounded-pill {
  border-radius: 16px !important;
}

.pwa-bordered {
  border: 1px solid #f0f0f0b0;
}

.pwa-bordered-bold {
  border: 4px solid #f0f0f0b0;
}

.custom-dropdown {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--bg-1);
  background-image: url('./assets/images//filled-bottom-arrow.png');
  background-position: right 10px center;
  background-size: 15px;
  background-repeat: no-repeat;
  padding: 0 30px 0 15px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  min-height: 43px;
}

.calender-scroll {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}
.calender-scroll::-webkit-scrollbar {
  display: none;
}

.main-content {
  overflow-y: auto !important;
}

.max-width-600 {
  max-width: 600px !important;
}
.max-width-700 {
  max-width: 700px !important;
}
.max-width-800 {
  max-width: 800px !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-12 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
